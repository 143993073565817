@font-face {
  font-family: 'Ogg';
  src: url('../fonts/Ogg-Roman.woff') format('woff');
}
@font-face {
  font-family: 'EuropaGroteskSH-Lig';
  src: url('../fonts/EuropaGroteskSH-Lig.otf');
}
html, body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  font-family: 'EuropaGroteskSH-Lig', sans-serif;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
}
ul.main {
  font-family: 'Ogg', serif;
  font-size: 23px;
}
ul.secondary {
  font-family: 'EuropaGroteskSH-Lig', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0.25em;
  line-height: .85;
  letter-spacing: 0.04em;
}

a, a:visited, a:active, a:hover {
  text-decoration: none;
  color: inherit;
  transition: color 200ms;
}
a:hover {
  color: $secondaryColor;
}

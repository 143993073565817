@import 'styles/reset';
@import 'styles/base';
@import 'styles/typography';
@import 'styles/figures';
@import url('https://unpkg.com/flickity@2.1.2/dist/flickity.min.css');

body {
  background-color: white;
}
@media screen and (min-width: 600px) {
  body.small {
    svg path {
      fill: black;
    }
    ul {
      color: black;
    }
  }
}

header {
  h1 {
    position: fixed;
    right: 1.5em;
    top: 1.75em;
    z-index: 1;
  }
  ul.main {
    position: fixed;
    z-index: 1;
  }
  ul#collection {
    left: 1.75em;
    top: 3em;
    li {
      position: relative;
      ul.tertiary {
        position: absolute;
        top: 0;
        left: calc(100% + 1em);
        width: 7em;
        display: none;
        height: 100%;
      }
    }
  }
  ul#shop {
    li {
      position: relative;
      ul.tertiary {
        position: absolute;
        top: 0;
        left: calc(100% + 1em);
        width: 7em;
        display: none;
        margin: 0;
        height: 100%;
        li.shop-item {
          width: 25em;
          height: 100%;
          transition: opacity 200ms ease;
          p {
            display: none;
            margin: 1em 0;
          }
          .shopify-buy-frame {
            display: none;
          }
        }
        li.hidden {
          opacity: 0;
          pointer-events: none;
        }
        li.active {
          opacity: 1 !important;
          pointer-events: all !important;
          p, .shopify-buy-frame {
            display: block;
          }
        }
      }
    }
  }
  ul.secondary {
    margin: 0;
    display: none;
  }
  ul.visible {
    display: block !important;
  }
  ul#shop {
    left: 10.75em;
    top: 7.6em;
  }
  ul#accessories {
    left: 1em;
    top: 10em;
    @media screen and (min-width: 600px) {
      left: 3.1em;
      top: 15.5em;
    }
  }
  ul#info {
    left: 4.7em;
    top: calc(100vh - 11em);
    @media screen and (min-width: 600px) {
      left: 8.7em;
      top: 22em;
    }
    li.info-text {
      max-width: 45em;
      p {
        margin: 0 0 1em 0;
      }
    }
  }
  ul.secondary, ul.tertiary {
    transform: scale(1, .85);
    line-height: 1;
  }
}

a.mobile {
  display: none;
}
.close-button {
  display: none;
}
@media screen and (max-width: 600px) {
  a.mobile {
    display: block !important;
  }
  a.desktop {
    display: none !important;
  }
  body.shop-menu-open {
    .close-button {
      display: block;
      position: fixed;
      top: 5rem;
      left: 0.5rem;
      font-size: 2em;
      z-index: 99;
    }
    ul {
      display: none;
    }
    ul#shop {
      display: block !important;
      position: absolute !important;
      top: -10em;
      left: 0em !important;
      li {
        position: inherit !important;
      }
      ul.secondary {
        display: block !important;
        li {
          position: inherit !important;
        }
      }
      ul.tertiary {
        display: block !important;
        position: absolute;
        top: 30em;
        left: .5em;
        li.desktop {
          display: none !important;
        }
        li.shop-item {
          position: static !important;
          margin: 0 !important;
          height: 1em !important;
          padding-left: 1em;
          p {
            display: none !important;
          }
        }
      }
    }
  }
}

body.shop, body.default, body.imprint {
  .imprint-menu {
    color: black;
  }
  color: black;
  padding: 5em 1.5em 1.5em 1.5em;
  box-sizing: border-box;
  width: 100vw;
  text-transform: uppercase;
  overflow-x: hidden;
  nav {
    display: none;
  }
  svg path {
    fill: black;
  }
  p {
    max-width: 41em;
    margin: 1em 0;
  }
  .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .product {
      box-sizing: border-box;
      @media screen and (max-width: 600px) {
        width: calc(50vw - 3em);
      }
      width: calc(20vw - 3em);
      margin: 1.5em;
      figure {
        img {
          width: 100%;
          height: auto;
        }
        margin-bottom: 1em;
      }
      h2 {
        color: $secondaryColor;
        margin-bottom: .5em;
      }
      span.price {
        color: black;
      }
    }
  }
}
body.product {
  color: black;
  padding: 5em 1.5em 1.5em 1.5em;
  box-sizing: border-box;
  width: 100vw;
  text-transform: uppercase;
  overflow-x: hidden;
  nav {
    display: none;
  }
  svg path {
    fill: black;
  }
  .product-detail {
    display: flex;
    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
    flex-direction: column;
  .carousel {
    width: 100%;
    @media screen and (min-width: 600px) {
      width: calc(50vw - .5em);
    }
    margin-bottom: 1em;
    height: 60vh;
    margin-right: 1em;
    .flickity-button {
      background: hsla(0,0%,100%,.0);
      svg path {
        transition: fill 200ms ease;
        fill: $secondaryColor;
      }
      &:hover {
        svg path {
          fill: black;
        }
      }
    }
    .carousel-cell {
      width: 100%;
      height: 100%;
      figure {
        width: 100%;
        height: 100%;
        text-align: center;
        img {
          object-fit: contain;
          object-position: center;
          height: 100%;
          width: auto;
        }
      }
    }
  }
    h2 {
      color: $secondaryColor;
      margin-bottom: .5em;
    }
    p {
      margin: 1em 0;
    }
  }
}
/* basicallly info page */
body.default, body.imprint {
  padding: 0;
  text-transform: none;
  .background-images {
    display: none;
  }
  #content {
    padding: 5em 1.5em 1.5em 1.5em;
  }
  figure {
    max-width: 65vw;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  p {
    _text-transform: none;
    transform: scale(1, .85);
    transform-origin: top;
    letter-spacing: 0.05em;
    line-height: 1.2;
  }
  @media screen and (min-width: 800px) {
    nav, .background-images {
      display: block;
    }
    #content {
      padding: 0;
      position: fixed;
      z-index: 1;
      top: 5em;
      left: 315px;
      p {
        max-width: 29em;
      }
      figure {
        margin-left: 8em;
        height: 31rem;
        width: auto;
        img {
          height: 100%;
          width: auto;
          max-width: auto;
        }
      }
    }
  }
}
.imprint-menu {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99;
}
@media screen and (min-width: 800px) {
  main.imprint {
    #content {
      top: 39.5em;
      transform: translateY(-100%);
    }
  }
}

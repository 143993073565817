.background-images {
  cursor: zoom-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .background-image img {
    width: 100vw !important;
    height: 100vh !important;
    object-fit: cover;
  }
}
body.small .background-images .background-image {
  cursor: zoom-in;
}
@media screen and (min-width: 600px) {
  body.small .background-images .background-image img {
    object-fit: contain !important;
  }
}

body.start .background-images .background-image {
  cursor: default;
}
